<template>
    <div class="payment-page">
        <div class="main-h1">Оплаты</div>
        <payment-list v-if="payments.length" :list="payments" @updated="getPayments" :key="payments.length"/>
    </div>
</template>

<script>
import paymentList from '../../../components/payment/list'
export default {
    name: "paymentPage",
    components:{paymentList},
    data() {
        return {
            payments:[],
        }
    },
    methods: {
        getPayments() {
            this.$api.get(`payment`).then(res => {
                if (res.data.success) {
                    this.payments = res.data.data
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
    },
    created() {
        this.getPayments();
    }
}
</script>

<style scoped>

</style>